@import "palettes";
@import "theme";
@import "mixins";

html,
body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

body {
  display: flex;
  flex-direction: column;
  font-family: $serif;

  main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  section {
    box-sizing: content-box;
    padding: 60px 40px; // fallback
    padding: 60px Max(40px, calc((100% - #{$page}) / 2));
    text-align: center;

    &[data-full="true"] {
      padding: 0;
    }
  }

  section:nth-of-type(even) {
    background: $white;
  }

  section:nth-of-type(even) {
    background: $gray-50;
  }

  section:last-of-type {
    flex-grow: 1;
  }
}
